<template>
  <div>
    <v-text-field
      v-model="user.firstname"
      label="First"
      hide-details
      outlined
      dense
      filled
      class="mt-3 mb-3"
    ></v-text-field>
    <v-text-field
      v-model="user.lastname"
      label="Last"
      hide-details
      outlined
      dense
      filled
      class="mb-3"
    ></v-text-field>
    <v-text-field
      v-model="user.username"
      label="Username"
      hide-details
      outlined
      dense
      filled
      class="mb-3"
    ></v-text-field>
    <v-text-field
      v-model="user.email"
      label="Email"
      hide-details
      outlined
      dense
      filled
      class="mb-3"
    ></v-text-field>
    <v-autocomplete
      v-model="user.organization.name"
      label="Organization"
      :items="organizations"
      item-text="name"
      item-value="name"
      hide-details
      outlined
      dense
      filled
      class="mb-3"
    ></v-autocomplete>
    <v-radio-group
      v-model="user.gender"
      label="Gender"
      row
      hide-details
      class="mt-3 mb-3"
    >
      <v-radio
        label="Male"
        value="MALE"
      ></v-radio>
      <v-radio
        label="Female"
        value="FEMALE"
      ></v-radio>
    </v-radio-group>

    <v-text-field
      v-if="user.telephoneNumbers"
      v-model="user.telephoneNumbers.LANDLINE_BUSINESS"
      label="Phone (office)"
      hide-details
      outlined
      dense
      filled
      class="mb-3"
    ></v-text-field>

    <v-text-field
      v-if="user.telephoneNumbers"
      v-model="user.telephoneNumbers.MOBILE"
      label="Phone (mobile)"
      hide-details
      outlined
      dense
      filled
      class="mb-3"
    ></v-text-field>

    <v-btn
      v-if="!user.telephoneNumbers"
      class="mb-3"
      @click="$set(user, 'telephoneNumbers',{})"
    >
      <v-icon
        color="green"
        left
      >
        add_circle
      </v-icon>
      Add phone numbers
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'UserBaseSettings',
    props: {
      user: {},
      organizations: {},
    },
  }
</script>
