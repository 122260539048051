<template>
  <div>
    <v-btn
      slot="activator"
      :fab="!label"
      dark
      :small="$attrs.small === undefined ? true : $attrs.small"
      :x-small="$attrs['x-small']"
      :color="dark ? 'white': color"
      :top="$attrs.top === undefined ? true : $attrs.top"
      :right="$attrs.right === undefined ? true : $attrs.right"
      :left="$attrs.left === undefined ? false : $attrs.left"
      :absolute="$attrs.absolute === undefined ? true : $attrs.absolute"
      :loading="buttonDialog"
      :text="text"
      :style="{...position,}"
      :outlined="outlined"
      @click.stop="buttonDialog = true"
    >
      <v-icon :left="!!label">
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
    <v-dialog
      v-model="buttonDialog"
      persistent
      max-width="350px"
    >
      <v-card>
        <v-card-title>
          <v-icon
            v-if="warnIcon"
            class="pr-2 yellow--text text--darken-2"
          >
            warning
          </v-icon>
          <h3>{{ title }}</h3>
        </v-card-title>
        <v-card-text>
          <slot>{{ message }}</slot>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            color="grey"
            @click="buttonDialog = false"
          >
            {{ cancelLabel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="color"
            :disabled="!confirmCondition"
            @click="confirm()"
          >
            {{ confirmLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ButtonConfirm',
    props: {
      title: {
        type: String,
        default: 'Are you sure?',
      },
      message: {
        type: String,
        default: 'This action cannot be undone',
      },
      cancelLabel: {
        type: String,
        default: 'Cancel',
      },
      confirmLabel: {
        type: String,
        default: 'Delete',
      },
      color: {
        type: String,
        default: 'error',
      },
      text: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: 'delete',
      },
      confirmCondition: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
        default: null,
      },
      warnIcon: {
        type: Boolean,
        default: true,
      },
      position: {
        type: String,
        default: '',
      },
      dark: {
        type: Boolean,
        default: false,
        required: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        buttonDialog: false,
      }
    },
    methods: {
      confirm() {
        this.$emit('confirmed')
        this.buttonDialog = false
      },
    },
  }
</script>
