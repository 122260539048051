let organizations = []
let users = []
let apps = []

let roles = {
  citrus: [
    {id: 'ADMIN', description: ''},
    {id: 'SUPERADMIN', description: ''},
    {id: 'RFQ_ISSUER', description: 'Emittenten für RFQ'},
    {id: 'RFQ_PM', description: 'Produktmanager für RFQ'},
    {id: 'ISSUER', description: 'Emittent'},
    {id: 'PM', description: 'Produktmanager'},
    {id: 'RFQ_CONSULTANT', description: 'RFQ Berater'},
  ],
}

const equivalenceCheck = {
  product: {
    "anlageklasse": 3,
    "anlageform": null,
    "attachments": [
      {
        "name": "IE00B3NHP925-ANNUAL_REPORT.pdf",
        "type": 9,
        "majorVersion": 1,
        "minorVersion": 0,
        "published": "2020-11-29T23:00:00.000+0000",
        "attachmentDate": "2020-11-29T23:00:00.000+0000",
        "attachment": null,
        "attachmentUrl": "http://documents.fww.info/fwwdok_yYyciPWQ8r.pdf",
      },
      {
        "name": "IE00B3NHP925-SEMI_ANNUAL_REPORT.pdf",
        "type": 10,
        "majorVersion": 1,
        "minorVersion": 0,
        "published": "2020-05-30T22:00:00.000+0000",
        "attachmentDate": "2020-05-30T22:00:00.000+0000",
        "attachment": null,
        "attachmentUrl": "http://documents.fww.info/fwwdok_6qY1wNjsm5.pdf",
      },
      {
        "name": "IE00B3NHP925-LEAFLET.pdf",
        "type": 11,
        "majorVersion": 1,
        "minorVersion": 0,
        "published": "2021-05-04T22:00:00.000+0000",
        "attachmentDate": "2021-05-04T22:00:00.000+0000",
        "attachment": null,
        "attachmentUrl": "http://documents.fww.info/fwwdok_83dX4UTVmZ.pdf",
      },
      {
        "name": "IE00B3NHP925-KID.pdf",
        "type": 7,
        "majorVersion": 1,
        "minorVersion": 0,
        "published": "2021-02-07T23:00:00.000+0000",
        "attachmentDate": "2021-02-07T23:00:00.000+0000",
        "attachment": null,
        "attachmentUrl": "http://documents.fww.info/fwwdok_VyPEHGYnwR.pdf",
      },
    ],
    "bondtype": 2,
    "currency": "EUR",
    "dbdfaehig": null,
    "documentQuelle": "NORD_LB",
    "aequivalenzData": {
      "bviFondskategorie": null,
      "kapitalgarantieKz": false,
      "waehrungssicherungKz": false,
      "zielrenditeKapitalerhaltKz": null,
      "zielrenditePositivkz": null,
      "anlagestrategieAktivKz": true,
      "anlagestrategieThematischKz": null,
      "anlagestrategiePassivKz": false,
      "managementAusrtgFundaKz": null,
      "managementAusrtgQuantKz": null,
      "managementansatzRisikoKz": null,
      "einmalZuwendungen": 0,
      "empfohleneHaltedauer": null,
      "laufendeZuwendungen": null,
      "aktienneutralquote": null,
      "nachhaltigkeit": null,
      "angabeZurNachhaltigkeit": "",
      "angabeZuNachhaltigkeitsrisiken": "",
    },
    "investmentCompany": null,
    "isin": "IE00B3NHP925",
    "name": "European Value Fund EUR Dis",
    "rating": 1,
    "researchQuelle": "NORD_LB",
    "ergebnisBestand": 3,
    "ergebnisVorschlag": 3,
    "angabeZurGeeignetheit": "Anlageziel ist die langfristige Wertsteigerung und Ertragserzielung. Der Fonds wird auf diskretionärer Basis verwaltet und investiert vornehmlich in Aktien von Unternehmen, die an den Hauptbörsen in ausgewählten europäischen Ländern notiert sind. Er verfolgt bei den Anlagen eine Value-Strategie, d.h. er investiert in Aktien von Unternehmen, bei denen der Preis zum Kaufzeitpunkt im Vergleich zum bilanziellen Unternehmenswert niedrig ist. Der Fonds kann Finanzkontrakte oder -instrumente (Derivate) einsetzen, um Risiken zu verwalten, Kosten zu senken oder Renditen zu steigern.",
    "type": null,
    "warnings": false,
    "yieldusage": null,
  },
  fields: [
    // {
    //   "label": "ISIN",
    //   "info": null,
    //   "path": "ids.isin",
    //   "decorator": "text",
    //   "decoratorOptions": null,
    //   "sortPath": null,
    //   "initialOrder": null,
    //   "weight": null,
    //   "pathToStyleHints": null,
    //   "splitIndicator": "NONE",
    // },
    {
      "label": "Einmalige Zuwendungen",
      "info": null,
      "path": "bison.aequivalenzData.einmalZuwendungen",
      "decorator": "percent",
      "decoratorOptions": {
        "format": "1.2-2",
        "styleHints": [
          "right-aligned",
        ],
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "aktive Anlagestrategie",
      "info": null,
      "path": "bison.aequivalenzData.anlagestrategieAktivKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "passive Anlagestrategie",
      "info": null,
      "path": "bison.aequivalenzData.anlagestrategiePassivKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Management Ausrichtung fundamental",
      "info": null,
      "path": "bison.aequivalenzData.managementAusrtgFundaKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Management Ausrichtung quantitativ",
      "info": null,
      "path": "bison.aequivalenzData.managementAusrtgQuantKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Kapitalgarantie",
      "info": null,
      "path": "bison.aequivalenzData.kapitalgarantieKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Anteilklassenwährung",
      "info": null,
      "path": "bison.currency",
      "decorator": "text",
      "decoratorOptions": null,
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Währungssicherung",
      "info": null,
      "path": "bison.aequivalenzData.waehrungssicherungKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "BVI Fonds-Kategorie Feingruppen",
      "info": null,
      "path": "bison.aequivalenzData.bviFondskategorie",
      "decorator": "text",
      "decoratorOptions": null,
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "thematische Anlagestrategie",
      "info": null,
      "path": "bison.aequivalenzData.anlagestrategieThematischKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "risikoorientierter Managementansatz",
      "info": null,
      "path": "bison.aequivalenzData.managementansatzRisikoKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Zielrendite: Wertuntergrenze",
      "info": null,
      "path": "bison.aequivalenzData.zielrenditeKapitalerhaltKz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Zielrendite: Ertragsziel (Positiv)",
      "info": null,
      "path": "bison.aequivalenzData.zielrenditePositivkz",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "j",
        "falsy": "n",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Empfohlene Haltedauer (RHP) in Jahren",
      "info": null,
      "path": "bison.aequivalenzData.empfohleneHaltedauer",
      "decorator": "text",
      "decoratorOptions": null,
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Aktienneutralquote (Mischfonds)",
      "info": null,
      "path": "bison.aequivalenzData.aktienneutralquote",
      "decorator": "percent",
      "decoratorOptions": {
        "format": "1.2-2",
        "styleHints": [
          "right-aligned",
        ],
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
    {
      "label": "Bison Produkt",
      "info": null,
      "path": "enriched.bison.isBisonProduct",
      "decorator": "boolean",
      "decoratorOptions": {
        "truthy": "Ja",
        "falsy": "Nein",
      },
      "sortPath": null,
      "initialOrder": null,
      "weight": null,
      "pathToStyleHints": null,
      "splitIndicator": "NONE",
    },
  ],
}

export { organizations, users, apps, roles, equivalenceCheck }
