<template>
  <v-container
    fluid
    grid-list-xl
    class="users"
  >
    <h1>{{ $t('sidebar.users') }}</h1>
    <v-row
      align="center"
      class="mt-3"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('actions.search')"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        xs="6"
        md="3"
      >
        <v-switch
          v-model="showOnlySelected"
          dense
          :label="$t('actions.showOnlySelected')"
          hide-details
          class="mt-0"
        ></v-switch>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <button-confirm
          v-if="selection.length > 0"
          :label="`Delete ${selection.length}`"
          :absolute="false"
          :small="false"
          confirm-label="Delete"
          :message="`Are you sure you want to delete ${messagesselection.length} users?`"
          title="Halt Stop!"
        ></button-confirm>
      </v-col>
      <v-col class="shrink">
        <v-btn
          color="primary"
          @click="openNewUserDialog()"
        >
          <v-icon left>
            add
          </v-icon>
          {{ $t('actions.createX', [$t('user.user')]) }}
        </v-btn>


        <v-dialog
          v-model="newUserDialog"
          max-width="500"
        >
          <v-card>
            <v-card-title>
              {{ $t('actions.createX', [ $t('user.user')]) }}
              <v-btn
                icon
                absolute
                right
                @click="newUserDialog = false"
              >
                <v-icon
                  color="accent"
                >
                  clear
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <user-base-settings
                :user="newUser"
                :organizations="organizations"
              ></user-base-settings>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    filled
                    class="mb-3"
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    filled
                    class="mb-3"
                    label="Password (repeat)"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="newUserDialog=false"
              >
                <v-icon>check</v-icon>
                {{ $t('actions.createX', [ $t('user.user')]) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selection"
          :items="filteredUsers"
          :headers="tableHeaders"
          multi-sort
          :search="search"
          :items-per-page="20"
          :footer-props="{
            itemsPerPageOptions: [20,40,100]
          }"
          :expanded.sync="expanded"
          show-expand
          show-select
          class="user-table"
        >
          <template
            v-slot:header.data-table-expand="{}"
          >
            <v-btn
              v-if="expanded.length > 0"
              icon
              large
              color="accent"
              @click="expanded = []"
            >
              <v-icon>unfold_less</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>

          <template v-slot:item.id-field="{item}">
            <id-field
              :id="item.id"
              class="mx-1"
            ></id-field>
          </template>

          <template v-slot:item.json="{item}">
            <v-btn
              icon
              large
              @click="openJsonDialog(item)"
            >
              <v-icon color="accent">
                code
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.data-table-expand="{ isExpanded, item, expand }">
            <v-btn
              icon
              large
              @click="isExpanded ? expand(false) : expand()"
            >
              <v-icon color="accent">
                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </v-btn>
          </template>
          <!--          <template v-slot:header.data-table-expand="{ header }">-->
          <!--          </template>-->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  lg="2"
                >
                  <UserBaseSettings
                    :user="item"
                    :organizations="organizations"
                  />
                </v-col>
                <v-col>
                  <v-tabs
                    v-model="selectedApp"
                    class="mt-3 mb-3"
                    vertical
                    show-arrows
                    :value="expanded"
                  >
                    <v-tab
                      v-for="tab in appIds"
                      :key="tab"
                    >
                      {{ tab }}
                    </v-tab>

                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-row class="pl-3">
                        <v-col
                          cols="12"
                          xs="12"
                          sm="6"
                          md="4"
                          lg="3"
                        >
                          <v-switch
                            v-model="item.appIds"
                            label="User has access"
                            value="citrus"
                          ></v-switch>

                          <template>
                            <v-text-field
                              label="Start page"
                              hint="Auf die Route in diesem Parameter wird der Benutzer nach erfolgreichem einloggen geleitet."
                              persistent-hint
                              outlined
                              dense
                              filled
                              class="mb-3"
                            ></v-text-field>
                            <v-text-field
                              label="Display name"
                              hint="Der Name, der dem Benutzer innerhalb CitrusNG über dem Menu angezeigt wird."
                              persistent-hint
                              outlined
                              dense
                              filled
                              class="mb-3"
                            ></v-text-field>
                            <v-text-field
                              label="PIB Legacy Upload"
                              hint="Der Wert '1' kann verwendet werden, falls der reguläre PIB Upload für Primärmarktprodukte nicht funktioniert."
                              persistent-hint
                              outlined
                              dense
                              filled
                              class="mb-3"
                            ></v-text-field>
                            <v-text-field
                              label="Statistik"
                              hint="Eine kommaseperierte Liste von Umgebungen, für die der Benutzer die Statistik sehen darf. Zusätzlich muss er die Rolle ISSUER haben."
                              persistent-hint
                              outlined
                              dense
                              filled
                              class="mb-3"
                            ></v-text-field>
                            <v-text-field
                              label="RFQ Dummy Mode"
                              hint="Wenn der Dummy Modus aktiviert ist, können RFQ Requests nicht angeschickt werden. 1 = aktiviert"
                              persistent-hint
                              outlined
                              dense
                              filled
                              class="mb-3"
                            ></v-text-field>
                          </template>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="12"
                          sm="6"
                          md="8"
                          lg="9"
                        >
                          <v-tabs
                            v-if="selectedApp !== null"
                            class="mt-3 mb-3"
                            show-arrows
                            :value="expanded"
                          >
                            <v-tab>Access rights</v-tab>
                            <v-tab>Legacy</v-tab>

                            <v-tab-item>
                              <v-divider></v-divider>
                              <v-row class="mt-2">
                                <v-col cols="12">
                                  <table>
                                    <tr>
                                      <th>Environment</th>
                                      <th>Groups</th>
                                      <th>Roles</th>
                                    </tr>
                                    <tr
                                      v-for="(instance, instanceKey) in accessRights[appIds[selectedApp]]"
                                      :key="instanceKey"
                                    >
                                      <td class="shrink">
                                        <v-subheader>
                                          {{ instanceKey }}
                                        </v-subheader>
                                      </td>
                                      <td>
                                        <v-select
                                          :key="instanceKey + 'group'"
                                          :items="instance.groups"
                                          :value="instance.groups"
                                          hide-details
                                          outlined
                                          filled
                                          multiple
                                          chips
                                          deletable-chips
                                          small-chips
                                        ></v-select>
                                      </td>
                                      <td>
                                        <v-select
                                          :key="instanceKey * 'role'"
                                          :items="instance.roles"
                                          :value="instance.roles"
                                          hide-details
                                          outlined
                                          filled
                                          multiple
                                          chips
                                          deletable-chips
                                          small-chips
                                        ></v-select>
                                      </td>
                                    </tr>
                                  </table>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                            <v-tab-item>
                              <v-divider></v-divider>
                              <v-col>
                                <v-switch
                                  v-for="role in roles.citrus"
                                  :key="'legacy-' + role.id"
                                  :label="`${role.id}${role.description ? ` (${role.description})` : ''}`"
                                  hide-details
                                ></v-switch>
                              </v-col>
                            </v-tab-item>
                          </v-tabs>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-row class="pl-3">
                        <v-col>
                          <v-switch
                            v-model="item.appIds"
                            label="User has access"
                            value="citrus"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-card flat>
                        <v-card-text>
                          <p>
                            Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
                            tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris
                            ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt
                            non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-card flat>
                        <v-card-text>
                          <p>
                            Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin
                            viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                            mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-card flat>
                        <v-card-text>
                          <p>
                            Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis
                            tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris
                            ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt
                            non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                      :transition="false"
                      :reverse-transition="false"
                    >
                      <v-card flat>
                        <v-card-text>
                          <p>
                            Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin
                            viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                            mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="jsonDialog"
      max-width="1200"
      persistent
    >
      <v-card v-if="json">
        <v-card-title>
          {{ `${json.firstname} ${json.lastname} (${json.username})` }}

          <v-btn
            icon
            absolute
            right
            @click="jsonDialog = false"
          >
            <v-icon
              color="accent"
            >
              clear
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <json-editor
            v-model="json"
          ></json-editor>
        </v-card-text>
        <v-card-actions>
          <v-col></v-col>
          <v-col class="shrink">
            <v-btn
              color="primary"
              @click="saveUser(json)"
            >
              <v-icon left>
                check
              </v-icon>
              Save
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { apps, organizations, roles, users } from "@/config/dummy"
  import JsonEditor from "@/components/common/JsonEditor"
  import UserBaseSettings from "@/components/users/UserBaseSettings"
  import ButtonConfirm from "@/components/common/ButtonConfirm"
  import IdField from "@/components/common/IdField"

  // todo
  // user id


  export default {
    name: "Users",
    components: {IdField, ButtonConfirm, UserBaseSettings, JsonEditor},
    data() {
      return {
        apps,
        users,
        organizations,
        roles,
        selectedApp: null,
        newUser: null,
        newUserDialog: false,
        newUserDefault: {
          "username": null,
          "firstname": null,
          "lastname": null,
          "gender": "MALE",
          "email": null,
          "organizationId": null,
          "appIds": [],
          "roles": {},
          "accessRights": {},
          "active": true,
          "deleted": false,
          "organization": {
            "name": "Dericon GmbH",
          },
        },
        appIds: ['derifin', 'citrus', 'files', 'derilytics', 'duse', 'rfq', 'mercury', 'citrus broker'],

        accessRights: {
          "derilytics": {
            "default": {
              "roles": [
                "581ba3b5e4b00b9c1e0ce798",
              ],
            },
          },
          "derifiles": {
            "default": {
              "roles": [
                "57a497710cf2d2fd6b3d0578",
              ],
            },
          },
          "duse": {
            "default": {
              "roles": [
                "57a497710cf2d2fd6b3d0578",
              ],
            },
          },
          "rfq": {
            "default": {
              "roles": [
                "581c199fe4b00b9c1e0ce7a4",
                "581c19b3e4b00b9c1e0ce7a5",
              ],
            },
          },
          "citrus": {
            "default": {
              "roles": [
                "57a497710cf2d2fd6b3d0578",
                "581ba3b5e4b00b9c1e0ce798",
                "581c19b3e4b00b9c1e0ce7a5",
                "581c19cbe4b00b9c1e0ce7a7",
              ],
            },
          },
          "derifin": {
            "default": {
              "groups": [
                "productManager",
              ],
              "roles": [
                "5b33afedbd26f46ee77b8c9a",
              ],
            },
            "QQD351NG": {
              "groups": [
                "9641b1a8923c421a836a72f3a00b1c5f",
              ],
              "roles": [],
            },
            "QQD3B2SN": {
              "groups": [
                "93418652087f42aaa80f26723f278f62",
                "admin",
              ],
              "roles": [],
            },
            "QQD3WMS1": {
              "groups": [
                "74ab37e5e27a42ef8a1d02dd83d00696",
              ],
              "roles": [],
            },
          },
          "mercury": {
            "QQM3D3R1": {
              "groups": [
                "abnAmro",
                "Berenberg",
                "admin",
                "bisonWmsContentReader",
              ],
              "roles": [],
            },
            "QQM3SPBR": {
              "groups": [
                "productManager",
              ],
              "roles": [],
            },
            "QQM3BLSK": {
              "groups": [
                "productManager",
              ],
              "roles": [],
            },
            "QQM3HANB": {
              "groups": [
                "admin",
              ],
              "roles": [],
            },
            "QQM3HAUK": {
              "groups": [
                "admin",
              ],
              "roles": [],
            },
            "QQM3NBNK": {
              "groups": [
                "admin",
              ],
              "roles": [],
            },
            "QQM3VDR0": {
              "groups": [
                "admin",
              ],
              "roles": [],
            },
            "QQM3BERE": {
              "groups": [
                "admin",
              ],
              "roles": [],
            },
            "QQM3D3GU": {
              "groups": [
                "admin",
              ],
              "roles": [],
            },
            "QQM3COM3": {
              "groups": [
                "contentCreator",
              ],
              "roles": [],
            },
            "QQM3FUGG": {
              "groups": [
                "contentCreator",
              ],
              "roles": [],
            },
          },
        },
        tree: null,
        jsonDialog: false,
        json: null,
        activeUser: null,
        showOnlySelected: false,
        search: null,
        selection: [],
        expanded: [],

      }
    },
    computed: {
      filteredUsers() {
        if (this.showOnlySelected) {
          return this.selection
        } else {
          return this.users
        }
      },
      tableHeaders() {
        return [
          {
            text: this.$t('user.first'),
            value: 'firstname',
          },
          {
            text: this.$t('user.last'),
            value: 'lastname',
          },
          {
            text: this.$t('user.username'),
            value: 'username',
          },
          {
            text: this.$t('user.organization'),
            value: 'organization.name',
          },
          {
            text: this.$t('user.email'),
            value: 'email',
          },
          {
            text: this.$t('user.createdAt'),
            value: 'createdAt',
          },
          {
            text: this.$t('user.active'),
            value: 'active',
          },
          {
            text: this.$t('user.deleted'),
            value: 'deleted',
          },
          {text: '', value: 'id-field', sortable: false, class: 'button-column'},
          {text: '', value: 'json', sortable: false, class: 'button-column'},
          {text: '', value: 'data-table-expand', sortable: false, class: 'button-column'},
        ]

      },
    },
    mounted() {
      this.expanded.push(this.users[0])
    }
    ,
    methods: {
      openNewUserDialog() {
        this.newUserDialog = true
        this.newUser = JSON.parse(JSON.stringify(this.newUserDefault))
      }
      ,
      saveUser(user) {
        console.log(user)
        this.$set(this.users, this.users.findIndex(el => el.id === user.id), user)
        this.jsonDialog = false
        this.json = null
      }
      ,
      openJsonDialog(user) {
        this.json = JSON.parse(JSON.stringify(user))
        this.jsonDialog = true
      }
      ,
      transformAccessRights(accessRights) {
        return accessRights.keys.map(el => {
          console.log(el)
        })
      },
    },
  }
</script>

<style lang="scss">
.button-column {
  width: 1px;
  min-width: 1px;
}

div.jsoneditor-menu {
  background-color: initial;
  border-bottom: none;
}

.users {
  .user-table {
    th {
      //font-size: 0.875rem !important
    }
  }
}
</style>
